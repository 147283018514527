import React from 'react';
import Image from 'next/image';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { SIGN_IN_URL } from 'util/constants';
import DefaultButton from '@src/components/ui-kit/buttons/DefaultButton';
import PartnersSection from './PartnersSection';
import { S3_IMAGES_URL } from 'util/constants';
import styles from './top-section.module.css';

const TopSection = () => {
  const { data: session } = useSession();
  const router = useRouter();

  const handleTryNow = () => {
    if (session) {
      router.push('/user/studio');
    } else {
      if (window) {
        window.location.assign(SIGN_IN_URL);
      }
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.leftWrapper}>
        <div className={styles.blurBg} />
        <div className={styles.leftContent}>
          <h3 className={styles.title}>OZONE STUDIO</h3>
          <p className={styles.subtitle}>
            Build, Iterate and publish Virtual Worlds like never before
          </p>
          <div className={styles.buttons}>
            <DefaultButton
              type='primary'
              style={{ width: '100%' }}
              onClick={handleTryNow}
              data-cy="create-scene-button"
            >
              <span style={{ color: '#fff' }}>
                {session ? 'Create scene' : 'Try Now'}
              </span>
            </DefaultButton>
            <DefaultButton type='secondaryRed' style={{ width: '100%' }}>
              <a href='#studio_features'>
                <span>Demo Worlds</span>
              </a>
            </DefaultButton>
          </div>
          <div className={styles.partnersWrapper}>
            <span className={styles.integration}>Integration with</span>
            <PartnersSection />
          </div>
        </div>
        <div className={styles.bg}>
          <Image
            alt='image'
            src={`${S3_IMAGES_URL}/assets/logos/studiobanner.jpg`}
            layout='fill'
            objectFit='cover'
            objectPosition='center'
          />
        </div>
      </div>
    </div>
  );
};

export default TopSection;
